import { NavLink } from "react-router-dom";
import navLogo from "../images/logoRGB.jpg"
import xico from '../images/x.png';
import listico from '../images/list.png';
const navlist=[
    {name:"O NAS", path:"/"},
    {name:"OFERTA", path:"/oferta"},
    {name:"CENNIK", path:"/cennik"},
    {name:"KONTAKT", path:"/kontakt"},
]
const showNavList=()=>{
    const showNav = document.getElementById("navLinks")
    if (showNav) {
        showNav.style.height="100%";
    }
}
const hideNavList=()=>{
    const hideNav = document.getElementById("navLinks")
    if(hideNav){
        hideNav.style.height="0";
    }
}
const Nav = () => {
    const menu=navlist.map(item=>(
        <div className="navDiv" key={item.name}>
            <NavLink id="NavLink" to={item.path} onClick={hideNavList}><span className="navLi">{item.name}</span></NavLink>
        </div>
    ))
    return ( 
        <div id="navComponent">
            <div id="navList">
                <div id="navImage" >
                    <NavLink id="NavLink" to="/"><span><img src={navLogo} alt="navLogo"></img></span></NavLink>
                </div>
                <div id="navLinks" className="nav-links">
                    <img src={xico} className="naviBtn" id='hideNavibtn' alt='buttonHide' onClick={hideNavList}/>
                    {menu}
                    <div id="navRedButton" >
                        <NavLink id="NavLink" to="/ksero" onClick={hideNavList}><span id="navRedButtonSpanLink"><p>PUNKT KSERO<br></br><span >DWORZEC SOPOT</span></p></span></NavLink>
                    </div>
                </div>
                <img src={listico} className="naviBtn" id='showNavibtn' alt='buttonShow' onClick={showNavList}/>
                
            </div>
        </div>
     );
}
 
export default Nav;