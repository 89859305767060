import background2 from "../images/background2.jpg"
import offer1 from "../images/offer1.jpg"
import offer2 from "../images/offer2.jpg"
import offer3 from "../images/offer3.jpg"
import offer4 from "../images/offer4.jpg"
import offer5 from "../images/offer5.jpg"
import offer6 from "../images/offer6.jpg"
const Oferta = () => {
    return ( 
        <div id="offerComponent">
            <img id="offerImageBackground" src={background2} alt="backgroundOfferComponent"></img>
            <div id="offerTextContainer"><p id="offerHeaderP">OFERTA</p></div>
            <div id="offerBlock">
                <div id="offerLineBlock">
                    <img className="offerImages" id="offerFirstImg" src={offer1} alt="offer1"></img>
                    <div id="offerFirstImageDiv">
                        <img className="offerImages" id="offerSecondImg" src={offer2} alt="offer2"></img>
                        <img className="offerImages" id="offerThirdImg" src={offer3} alt="offer3"></img>
                    </div>
                    <img className="offerImages" id="offerFourImg" src={offer4} alt="offer4"></img>
                    <div id="offerSecondImageDiv">
                        <img className="offerImages" id="offerFiveImg" src={offer5} alt="offer5"></img>
                        <img className="offerImages" id="offerSixImg" src={offer6} alt="offer6"></img>
                    </div>
                </div>
            </div>
            <div id="offerSecondBlock">
                <div id="offerTextBlock">
                    <p id="offerFirstParagraph">Proponujemy szybką i sprawną obsługę w zakresie opracowań graficznych oraz wydruków wszelkiego formatu folderów, plakatów czy banerów. Skanujemy wielkoformatowe rysunki CAD, mapy, projekty architektoniczne czy plany budowy, plakaty i zdjęcia. Obsługujemy TIFF-y, PDF-y, JPEG-i, PNG-i, EPS-y, CDR-y... <br></br> Dysponujemy skanerami, które pozwalają na skanowanie dokumentów o prawie metrowej szerokości, mono i w kolorze. Obsługujemy również zlecenia wielkonakładowe, czuwając nad całością prac graficznych i drukarskich. <br></br><br></br> Wykonane u nas metodą pigmentową wydruki wielkoformatowe cechuje wysoka odporność na wilgoć i promenie UV oraz 75-letnia gwarancja odporności kolorów na działanie światła.</p>
                    <h2 id="offerFirstH2">Terminowość realizacji zlecenia, jakość druku i konkurencyjna cena to podstawy, na jakich opieramy naszą działalność.</h2>
                    <ul id="offerUL">
                        <li>wydruki (A4 - E+)</li>
                        <li>ksero (A4 - E+)</li>
                        <li>skanowanie (A4 - E+)</li>
                        <li>druk plansz wielkoformatowych (gramatura papieru do 200g)</li>
                        <li>pieczątki (Trodat, Wagraf)</li>
                        <li>wizytówki</li>
                        <li>ulotki</li>
                        <li>foldery</li>
                        <li>plakaty</li>
                        <li>naklejki</li>
                        <li>laminowanie (do formatu A3)</li>
                        <li>bindowanie</li>
                        <li>oprawa prac</li>
                        <li>projektowanie graficzne</li>
                    </ul>
                    <h2 id="offerSecondH2">Rabat dla studentów - 15%</h2>
                    <p id="offerSecondParagraph">na ksero oraz wydruki dydaktyczne <br></br> (za okazaniem ważnej legitymacji)</p>
                    <p id="offerFourParagraph">Przy większym zamówieniu <br></br>dowóz wykonanej dokumentacji do klienta gratis</p>
                    <p id="offerThirdParagraph">Zapraszamy do współpracy</p>
                </div>
            </div>
        </div>
     );
}
 
export default Oferta;