import {BrowserRouter as Router} from 'react-router-dom'
import {Route, Routes} from "react-router-dom";
import './App.css';
import Footer from './components/stopka';
import Glowna from './components/glowna';
import Nav from './components/nav';
import Oferta from './components/oferta';
import Cennik from './components/cennik';
import Ksero from './components/ksero';
import Kontakt from './components/kontakt';

function App() {
  return (
    <div id='mainAppComponent'>
      <Router>
        <Nav/>
        <Routes>
              <Route path='/' element={<Glowna/>}/>
              <Route path='/oferta' element={<Oferta/>}/>
              <Route path='/cennik' element={<Cennik/>}/>
              <Route path='/kontakt' element={<Kontakt/>}/>
              <Route path='/ksero' element={<Ksero/>}/>
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
