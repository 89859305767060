import footerLogo from "../images/konik.png"
const Stopka = () => {
    return ( 
        <div id="footerComponent">
            <img src={footerLogo} alt="konik"></img>
            <div id="footerTextContainer">
                <div><a href="tel:58 733 98 25">58 733 98 25</a> <a href="mailto:biuro@sprint712.pl">biuro@sprint712.pl</a></div>
            </div>
        </div>
     );
}
 
export default Stopka;