import background3 from "../images/main2.jpg"
const Cennik = () => {
    return ( 
    <div id="priceComponent">
        <img id="priceImageBackground" src={background3} alt="backgroundPriceComponent"></img>
        <div id="priceTextContainer"><p id="priceHeaderP">CENNIK</p></div>
        <div id="priceAllOffertsBlock">
            <div id="priceFirstBlockRed">
                <h3>Rabat dla studentów<br></br>- 15%</h3>
                <p>na ksero oraz wydruki dydaktyczne<br></br>(za okazaniem ważnej legitymacji)</p>
            </div>
            <div className="priceLineBlock"></div>
            <div id="priceFirstBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>WYDRUKI DO A3 CZ-B</span></div>
                <h2>A5/A4 papier biały 1-str.<span> do 3szt.</span></h2><p>2,00 zł</p>
                <h2>A5/A4 papier biały 1-str.<span> od 4 do 10szt.</span></h2><p>1,00 zł</p>
                <h2>A5/A4 papier biały 1-str.<span> od 11 do 100szt.</span></h2><p>0,50 zł</p>
                <h2>A5/A4 papier biały 1-str.<span> od 101 do 500szt.</span></h2><p>0,40 zł</p>
                <h2>A5/A4 papier biały 1-str.<span> powyżej 500szt.</span></h2><p >0,30 zł</p>
                <br></br><br></br>
                <h2>A5/A4 papier biały 1-str.<span> student</span></h2><p >0,30 zł</p>
                <br></br><br></br>
                <h2>A3 papier biały 1-str.</h2><p>1,00 zł</p>
                <h2>A3 papier biały 2-str.</h2><p>2,00 zł</p>
                <br></br><br></br>
                <h2>A4 papier/folia sam.</h2><p>8,50 zł</p>
            </div>
            <div id="priceSecondBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>WYDRUKI DO A3 KOLOR</span></div>
                <h2>A5/A4 papier biały 1-str.</h2><p>3,00 zł</p>
                <h2>A5/A4 papier biały 2-str.</h2><p>6,00 zł</p>
                <br></br><br></br>
                <h2>A5/A4 karton biały 1-str.</h2><p>4,00 zł</p>
                <h2>A5/A4 karton biały 2-str.</h2><p>8,00 zł</p>
                <br></br><br></br>
                <h2>A3 papier biały 1-str.</h2><p>6,00 zł</p>
                <h2>A3 papier biały 2-str.</h2><p>12,00 zł</p>
                <br></br><br></br>
                <h2>A3 karton biały 1-str.</h2><p>8,00 zł</p>
                <h2>A3 karton biały 2-str.</h2><p>16,00 zł</p>
                <br></br><br></br>
                <h2>A4 papier/folia sam.</h2><p>8,50 zł</p>
            </div>
            <div id="priceThirdBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>WYDRUKI WIELKOFORMATOWE CZ-B</span></div>
                <h3>druk rysunków technicznych, papier cienki 80g</h3>
                <h2>A3 papier biały</h2><p>2,28 zł</p>
                <h2>A2 papier biały</h2><p>4,75 zł</p>
                <h2>A1 papier biały</h2><p>9,50 zł</p>
                <h2>A0 papier biały</h2><p>19,00 zł</p>
                <h2>E+ papier biały</h2><p>21,09 zł</p>
            </div>
            <div id="priceFourBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>WYDRUKI WIELKOFORMATOWE KOLOR</span></div>
                <h2>rys. techniczny papier 80g</h2><p>29,00 zł/m<sup>2</sup></p>
                <h2>wydruk grafiki papier 80g</h2><p>70,00 zł/m<sup>2</sup></p>
                <h2>papier matowy 170g</h2><p>140,00 zł/m<sup>2</sup></p>
                <h2>papier foto błysk</h2><p>170,00 zł/m<sup>2</sup></p>
                <h2>papier foto półmat</h2><p>170,00 zł/m<sup>2</sup></p>
                <h2>płótno (Canvas)</h2><p>260,00 zł/m<sup>2</sup></p>
            </div>
            <div className="priceLineBlock"></div>
            <div id="priceFiveBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>SKANOWANIE</span></div>
                <h2>do formatu A3 do 30 szt.</h2><p>1,00 zł</p>
                <h2>do formatu A3 31 szt. - 60 szt.</h2><p>0,70 zł</p>
                <h2>do formatu A3 powyżej 61 szt.</h2><p>0,50 zł</p>
                <h2>cz-b wielkoformatowe</h2><p>20,00 zł/m<sup>2</sup></p>
                <h2>kolorowe wielkoformatowe</h2><p>20,00 zł/m<sup>2</sup></p>
                <h2>CD + nagranie</h2><p>10,00 zł</p>
                <h2>nagranie na nośnik klienta</h2><p>5,00 zł</p>
            </div>
            <div id="priceSixBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>LAMINOWANIE</span></div>
                <h2>A5 błysk</h2><p>4,00 zł</p>
                <h2>A4 błysk</h2><p>5,50 zł</p>
                <h2>A3 błysk</h2><p>8,00 zł</p>
                <h2>A4 samoprzylepny</h2><p>6,50 zł</p>
                <h2>A3 samoprzylepny</h2><p>8,50 zł</p>
                <h2>A4 mat</h2><p>7,00 zł</p>
                <h2>A3 mat</h2><p>9,00 zł</p>
            </div>
            <div className="priceLineBlock"></div>
            <div id="priceSevenBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>PIECZĄTKI</span></div>
                <h2>1 wiersz standard</h2><p>9,00 zł</p>
                <h2>1 wiersz pow. 6mm</h2><p>10,00 zł</p>
                <h2>1 wiersz owalny</h2><p>12,00 zł</p>
                <h2>logo, grafika</h2><p>od 22,00 zł</p>
                <h2>wymiana gumki w oprawie klienta</h2><p>od 5,00 zł</p>
            </div>
            <div id="priceEightBlock" className="priceBlocks">
                    <div className="priceBlocksDiv"><span>OPRAWY*</span></div>
                    <h6 className="priceOprawyH6">Trodat 4911</h6><span className="priceOprawySpan">(38mm x 14mm)</span><p>40,00 zł</p>
                    <h6 className="priceOprawyH6">Trodat 4912</h6><span className="priceOprawySpan">(47mm x 18mm)</span><p>49,00 zł</p>
                    <h6 className="priceOprawyH6">Trodat 4913</h6><span className="priceOprawySpan">(58mm x 22mm)</span><p>53,00 zł</p>
                    <h6 className="priceOprawyH6">Trodat 4915</h6><span className="priceOprawySpan">(70mm x 25mm)</span><p>88,00 zł</p>
                    <br></br>
                    <h6 className="priceOprawyH6">Wagraf 2S</h6><span className="priceOprawySpan">(39mm x 15mm)</span><p>30,00 zł</p>
                    <h6 className="priceOprawyH6">Wagraf 3S</h6><span className="priceOprawySpan">(48mm x 19mm)</span><p>33,00 zł</p>
                    <h6 className="priceOprawyH6">Wagraf 4S</h6><span className="priceOprawySpan">(59mm x 25mm)</span><p>35,00 zł</p>
            </div>
            <div id="priceNineBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>WKŁADKI I TUSZE*</span></div>
                <h2>Trodat 4911,Wagraf 2s</h2><p>17,00 zł</p>
                <h2>Trodat 4912,Wagraf 3s</h2><p>19,00 zł</p>
                <h2>Trodat 4913,Wagraf 4s</h2><p>21,00 zł</p>
                <h2>tusz zwykły trodat</h2><p>27,00 zł</p>
                <h2>dotuszowanie pieczątki</h2><p>5,00 zł</p>
                <h2>wymiana gumki w pieczątce</h2><p>5,00 zł</p>
            </div>
            <div id="priceTenBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>DATOWNIKI*</span></div>
                <h2>Trodat 4810</h2><p>30,00 zł</p>
                <h2>Trodat 5430</h2><p>163,00 zł</p>
                <h2>Trodat 5440</h2><p>230,00 zł</p>
            </div>
            <div className="priceLineBlock"></div>
            <div id="priceElevenBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>WIZYTÓWKI DRUK CYFROWY</span></div>
                <table>
                    <tr>
                        <th>nakład</th>
                        <th>1-str.</th>
                        <th>2-str.</th>
                    </tr>
                    <tr>
                        <td className="priceBlockTableTd">50</td>
                        <td className="priceBlockTableTd">60,00 zł</td>
                        <td className="priceBlockTableTd">85,00 zł</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>75,00 zł</td>
                        <td>100,00 zł</td>
                    </tr>
                </table>
                <h4>papier:</h4><h5>satynowany 350g</h5>
                <h4>wymiary:</h4><h5>90x50mm / 85x55mm</h5>
                <h4>termin:</h4><h5>od 24h</h5>
            </div>
            <div id="priceTwelveBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>WIZYTÓWKI P. OZDOBNY/EKOLOGICZNY</span></div>
                <table>
                    <tr>
                        <th>nakład</th>
                        <th>1-str.</th>
                        <th>2-str.</th>
                    </tr>
                    <tr>
                        <td className="priceBlockTableTd">50</td>
                        <td className="priceBlockTableTd">70,00 zł</td>
                        <td className="priceBlockTableTd">95,00 zł</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>90,00 zł</td>
                        <td>115,00 zł</td>
                    </tr>
                </table>
                <h4>wymiary:</h4><h5>90x50mm / 85x55mm</h5>
                <h4>termin:</h4><h5>od 24h</h5>
            </div>
            <div id="priceThirdteenBlock" className="priceBlocks">
                <div className="priceBlocksDiv"><span>WIZYTÓWKI DRUK OFFSET*</span></div>
                <table>
                    <tr>
                        <th>nakład</th>
                        <th>lakier dyspersyjny</th>
                        <th>softskin / błysk</th>
                        <th>lakier punktowy UV 3D</th>
                    </tr>
                    <tr>
                        <td className="priceBlockTableTd">250</td>
                        <td className="priceBlockTableTd">125,00 zł</td>
                        <td className="priceBlockTableTd">215,00 zł</td>
                        <td className="priceBlockTableTd">385,00 zł</td>
                    </tr>
                    <tr>
                        <td>500</td>
                        <td>185,00 zł</td>
                        <td>265,00 zł</td>
                        <td>465,00 zł</td>
                    </tr>
                    <tr>
                        <td className="priceBlockTableTd">1000</td>
                        <td className="priceBlockTableTd">265,00 zł</td>
                        <td className="priceBlockTableTd">355,00 zł</td>
                        <td className="priceBlockTableTd"></td>
                    </tr>
                </table>
                <h4 className="priceBlocksTableH2">papier:</h4><h5>kreda 350g</h5>
                <h4>wymiary:</h4><h5>90x50mm / 85x55mm</h5>
                <h4>termin:</h4><h5>3-7 dni roboczych</h5>
            </div>

        </div>
    </div> 
);
}
 
export default Cennik;