import background1 from "../images/background1.jpg";
import redDot from "../images/red-circle-icon.png";
import main1 from "../images/main1.jpg"
import main2 from "../images/main2.jpg"
import main3 from "../images/main3.jpg"
import main4 from "../images/main4.jpg"
const Glowna = () => {
    return ( 
        <div id="mainComponent">
            <img id="mainImageBackground" src={background1} alt="backgroundMainComponent"></img>
            <div id="mainHeader">
                <div className="mainHeaderDiv"><p className="mainHeaderP">DRUK</p></div>
                <div className="mainHeaderDiv"><img className="mainRedDot" src={redDot} alt="dot"></img></div>
                <div className="mainHeaderDiv"><p className="mainHeaderP">KSERO</p></div>
                <div className="mainHeaderDiv"><img className="mainRedDot" src={redDot} alt="dot"></img></div>
                <div className="mainHeaderDiv"><p className="mainHeaderP">PROJEKTOWANIE</p></div>
                <div id="mainHeaderBottom"><p id="mainHeaderBottomP">SOPOT</p></div>
            </div>
            <div id="mainTextContainer">
                <div id="mainTextFirstDiv">
                    <img src={main1} alt="main1"></img>
                    <img src={main2} alt="main2"></img>
                    <img src={main3} alt="main3"></img>
                    <img src={main4} alt="main4"></img>
                </div>
                <div id="mainTextSecondDiv">
                    <p className="mainTextP"><span>Studio Graficzne Sprint 712</span> to zespół grafików zajmujących się projektowaniem oraz drukiem. Posiadamy wiedzę oraz wieloletnie doświadczenie w branży, jak również znajomość rynku i ogromny dorobek wykonanych i zrealizowanych prac.<br></br><br></br></p>
                    <p className="mainTextP">Nasza oferta skierowana jest i dostosowana do różnych grup klientów i ich potrzeb oraz możliwości finansowych. Staramy się być zarówno wykonawcą jak i partnerem, który doradzi i zaproponuje najlepsze rozwiązanie.<br></br><br></br></p>
                    <p className="mainTextP">Oferujemy profesjonalne usługi druku i kserokopii studentom, prywatnym osobom, firmom i instytucjom. Jesteśmy otwarci na wszelkiego rodzaju zlecenia, do każdego z nich podchodzimy z pasją oraz pełnym zaangażowaniem.<br></br><br></br></p>
                    <p className="mainTextP">Mieścimy sie na pierwszym piętrze w pawilonach przy skrzyżowaniu al. Niepodległości i 3-go Maja. 750 metrów od centrum Sopotu, z możliwością bezpłatnego parkowania do 30min.<br></br><br></br></p>
                </div>
            </div>
        </div>
     );
}
 
export default Glowna;