import background3 from "../images/background3.jpg"
import ksero1 from "../images/ksero1.jpg"
import ksero2 from "../images/ksero2.jpg"
import lisek from "../images/lisek.png"
const Ksero = () => {
    return ( 
        <div id="kseroComponent">
            <img id="kseroImageBackground" src={background3} alt="backgroundKseroComponent"></img>
            <div id="kseroTextContainer">
                <h2>PUNKT KSERO</h2>
                <h3>DWORZEC SOPOT</h3>
            </div>
            <div id="kseroFirstBlock">
                <img src={ksero1} alt="ksero1"></img>
                <img src={ksero2} alt="ksero2"></img>
                <div id="kseroFirstBlockText">
                    <h3>Rabat dla studentów<br></br>- 15%</h3>
                    <p>na ksero oraz wydruki dydaktyczne<br></br>(za okazaniem ważnej legitymacji)</p>
                </div>
            </div>
            <div id="kseroSecondBlock">
                <div id="kseroSecondBlockFirstText">
                    <p>Punkt ksero mieści się w Sopot Centrum - Dworzec PKP Sopot, poziom 0, vis a vis sklepu Pepco</p>
                    <ul>
                        <li>wydruki (A4 - A3)</li>
                        <li>ksero (A4 - A3)</li>
                        <li>punkt przyjęć zleceń na:
                            <span><br></br>pieczątki (Trodat,Wagraf)<br></br>druk wizytówek<br></br>druk ulotek<br></br>druk plakatów</span>
                        </li>
                        <li>naklejki (A4)</li>
                        <li>laminowanie (do formatu A3)</li>
                        <li>bindowanie</li>
                        <li>oprawa prac</li>
                    </ul>
                </div>
                <img src={lisek} alt="lisek"></img>
                <div id="kseroSecondBlockSecondText">
                    <h1><span>WYDRUKI DO A3 CZ-B</span></h1>
                    <p>A5/A4 papier biały 1-str. do 3szt.</p><span className="kseroPinlineSpan">1,50 zł</span>
                    <p>A5/A4 papier biały 1-str. od 4 do 10szt.</p><span className="kseroPinlineSpan">1,00 zł</span>
                    <p>A5/A4 papier biały 1-str. od 11 do 100szt.</p><span className="kseroPinlineSpan">0,50 zł</span>
                    <p>A5/A4 papier biały 1-str. od 101 do 500szt.</p><span className="kseroPinlineSpan">0,40 zł</span>
                    <p>A5/A4 papier biały 1-str. powyżej 500szt.</p><span className="kseroPinlineSpan">0,30 zł</span>
                    <br></br><br></br>
                    <p>A3 papier biały 1-str.</p><span className="kseroPinlineSpan">1,00zł</span>
                </div>
            </div>
            <div id="kseroContactBlock">
                <div className="kseroContacts">
                    <h2>ADRES:</h2>
                    <p>DWORZEC SOPOT PKP SOPOT<br></br>81-704 Sopot, ul. Dworcowa 7</p>
                </div>
                <div className="kseroContacts">
                    <h2>KONTAKT:</h2>
                    <p>tel. <a href="tel:587650700">58 765 07 00</a><br></br>tel. kom. <a href="tel:665061906">665 061 906</a><br></br><a href="mailto:dworzecsopot@sprint712.pl">dworzecsopot@sprint712.pl</a></p>
                </div>
                <div className="kseroContacts">
                    <h2>GODZINY OTWARCIA:</h2>
                    <p>pon. - pt.<br></br>9.00 - 17.00</p>
                </div>
            </div>
        </div>
     );
}
 
export default Ksero;