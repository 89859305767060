import background4 from "../images/background4.jpg"
const Kontakt = () => {
    return ( 
        <div id="contactComponent">
            <img id="contactImageBackground" src={background4} alt="backgroundContactComponent"></img>
            <div id="contactTextContainer"><p id="contactHeaderP">KONTAKT</p></div>
            <div id="contactAllBlockContainer">
                <div id="contactBlockContainer">
                    <h2>DANE KONTAKTOWE</h2>
                    <div>
                        <p>81-853 Sopot <br></br>Al. Niepodległości 712<br></br>NIP: 5851464972</p>
                    </div>
                    <div id="contactCenterBlock">
                        <p>Godziny otwarcia: <br></br>Pon. - Pt. 8.00-18.00 <br></br>Sobota 9.00-13.00</p>
                    </div>
                    <div>
                        <p><a href="tel:587339825">tel. 58 733 98 25</a><br></br><a href="tel:665062081">kom. 665 062 081</a><br></br><a href="mailto:biuro@sprint712.pl">biuro@sprint712.pl</a></p>
                    </div>
                </div>
            </div>
            <iframe id="contactMap" title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2320.619435856196!2d18.558424077127853!3d54.434352092762744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd0a96039e98ef%3A0x8b0ee280ef021950!2sSprint%20712!5e0!3m2!1sen!2spl!4v1720866318168!5m2!1sen!2spl" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
     );
}
 
export default Kontakt;